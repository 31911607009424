import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Output() linkClick = new EventEmitter();
  downloadUrl = `${environment.apiUrl}advertisement`;
  @Input() position: 'header' | 'footer';
  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.linkClick.emit();
    }
  }
}
