import {Component, OnInit, Input} from '@angular/core';
import {EnvironmentService} from '@thebell/common/services/core/environment';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtonsComponent implements OnInit {
  constructor(private envService: EnvironmentService) {}

  @Input() postSlug: string;
  @Input() text: string;
  url = '';

  ngOnInit(): void {
    const {appUrl} = this.envService.getEnvironment();
    this.url = `${appUrl}${this.postSlug}`;
  }

  onClick(buttonType: string) {
    ga('send', 'event', `post_share_${buttonType}`, this.postSlug);
    dataLayer.push({
      'event': 'share', 'category': 'share', 'method': buttonType
    });
  }
}
